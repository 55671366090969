import React from 'react';
import {
  CheckCircleOutlined,
  CheckOutlined,
  DownOutlined,
  SearchOutlined
} from '@ant-design/icons';
import {
  VeltCommentDialogWireframe,
  VeltCommentsSidebarWireframe,
  VeltCommentThreadWireframe,
  VeltData,
  VeltIf
} from '@veltdev/react';
import styled, { createGlobalStyle } from 'styled-components';

import { Underline } from './NotificationsWireframe';
import { Box, Flex, Grid } from '../../theme/components';

const DownOutlinedStyled = styled(DownOutlined)`
  color: ${({ theme }) => theme.colors.text.secondary};
`;

const CheckOutlinedStyled = styled(CheckOutlined)`
  color: ${({ theme }) => theme.colors.text.default};
`;

const BoldAndCapitalize = styled.span`
  font-weight: bold;
  text-transform: capitalize;
  display: flex;
  align-items: center;
`;

const Clickable = styled(Flex)`
  cursor: pointer;
`;

export const CommentsSidebarWireframes = () => (
  <>
    {/* Status Checkbox dropdown */}
    <VeltCommentsSidebarWireframe.Status.Content.Item.Checkbox>
      <VeltCommentsSidebarWireframe.Status.Content.Item.Checkbox.Checked>
        <CheckOutlined />
      </VeltCommentsSidebarWireframe.Status.Content.Item.Checkbox.Checked>
      <VeltCommentsSidebarWireframe.Status.Content.Item.Checkbox.Unchecked />
    </VeltCommentsSidebarWireframe.Status.Content.Item.Checkbox>

    <VeltCommentDialogWireframe.AssigneeBanner.ResolveButton>
      <CheckOutlinedStyled />
    </VeltCommentDialogWireframe.AssigneeBanner.ResolveButton>

    {/* Comment Dialog Header */}
    <VeltCommentDialogWireframe.Header>
      <Flex alignItems="center" justifyContent="space-between">
        <VeltCommentDialogWireframe.Status />
        <Flex alignItems="center" gap={1}>
          <VeltCommentDialogWireframe.Options>
            <VeltCommentDialogWireframe.Options.Trigger />
            <VeltCommentDialogWireframe.Options.Content>
              <VeltCommentDialogWireframe.Options.Content.Assign />
              <VeltCommentDialogWireframe.Options.Content.Edit />
              <VeltCommentDialogWireframe.Options.Content.Notification>
                <VeltCommentDialogWireframe.Options.Content.Notification.Subscribe />
                <VeltCommentDialogWireframe.Options.Content.Notification.Unsubscribe />
              </VeltCommentDialogWireframe.Options.Content.Notification>
              <VeltCommentDialogWireframe.Options.Content.Delete />
            </VeltCommentDialogWireframe.Options.Content>
          </VeltCommentDialogWireframe.Options>
          <VeltCommentDialogWireframe.ResolveButton />
          <VeltCommentDialogWireframe.NavigationButton />
        </Flex>
      </Flex>
    </VeltCommentDialogWireframe.Header>

    <VeltCommentDialogWireframe.Body>
      <VeltCommentDialogWireframe.Threads />
    </VeltCommentDialogWireframe.Body>

    {/* Velt Standalone Dialog Wireframe */}
    <VeltCommentThreadWireframe>
      <VeltCommentDialogWireframe>
        <VeltCommentDialogWireframe.Body>
          <VeltCommentDialogWireframe.Threads>
            <VeltCommentDialogWireframe.ThreadCard>
              <Grid gridTemplateColumns="auto 1fr auto" alignItems="start">
                <Box pr={4}>
                  <VeltCommentDialogWireframe.ThreadCard.Avatar />
                </Box>
                <Box>
                  <Box display="inline">
                    <BoldAndCapitalize>
                      <VeltCommentDialogWireframe.ThreadCard.Name />
                      <VeltCommentDialogWireframe.ThreadCard.Unread />
                    </BoldAndCapitalize>
                    <VeltIf condition="{comment.commentId} === {commentAnnotation.comments[0].commentId}">
                      <span className="commented-in-text"> commented in </span>
                      <Underline className="commented-in-text">
                        <VeltData path="commentAnnotation.context.groupName" />
                      </Underline>
                    </VeltIf>
                  </Box>
                  <Box>
                    <VeltCommentDialogWireframe.ThreadCard.Time />
                  </Box>
                  <Box mb={2}>
                    <VeltCommentDialogWireframe.ThreadCard.Message />
                  </Box>
                  <VeltCommentDialogWireframe.ToggleReply />
                </Box>
              </Grid>
            </VeltCommentDialogWireframe.ThreadCard>
          </VeltCommentDialogWireframe.Threads>
        </VeltCommentDialogWireframe.Body>
      </VeltCommentDialogWireframe>
    </VeltCommentThreadWireframe>

    {/* Comment Dialog Thread Card */}
    <VeltCommentDialogWireframe.ThreadCard>
      <Grid gridTemplateColumns="auto 1fr auto" alignItems="start">
        <Box pr={4}>
          <VeltCommentDialogWireframe.ThreadCard.Avatar />
        </Box>
        <Box>
          <Box display="inline">
            <BoldAndCapitalize>
              <VeltCommentDialogWireframe.ThreadCard.Name />
              <VeltCommentDialogWireframe.ThreadCard.Unread />
            </BoldAndCapitalize>
            <VeltIf condition="{comment.commentId} === {commentAnnotation.comments[0].commentId} && {commentAnnotation.context.commentType} === 'Cell'">
              <span className="commented-in-text"> commented in </span>

              <Underline className="commented-in-text">
                <VeltData path="commentAnnotation.context.underlinedText" />
              </Underline>
            </VeltIf>
          </Box>
          <Box>
            <VeltCommentDialogWireframe.ThreadCard.Time />
          </Box>
          <Box mb={2}>
            <VeltCommentDialogWireframe.ThreadCard.Message />
          </Box>
          <VeltCommentDialogWireframe.ToggleReply />
        </Box>
      </Grid>
    </VeltCommentDialogWireframe.ThreadCard>

    {/* Comments Sidebar */}
    <VeltCommentsSidebarWireframe variant="rolling">
      <VeltCommentsSidebarWireframe.Skeleton />
      <VeltCommentsSidebarWireframe.Panel>
        <Flex
          height="100%"
          width="100%"
          backgroundColor="white"
          border="none"
          flexDirection="column"
          padding="0"
        >
          <VeltCommentsSidebarWireframe.Header>
            <Flex alignItems="center" justifyContent="space-between">
              <Flex alignItems="center">
                <Box fontSize="16px" fontWeight={500} mr={3}>
                  Comments
                </Box>
                <Flex fontSize="12px" alignItems="center" gap={0.5}>
                  <SearchOutlined />
                  <VeltCommentsSidebarWireframe.Search />
                </Flex>
              </Flex>
              <VeltCommentsSidebarWireframe.Status>
                <VeltCommentsSidebarWireframe.Status.Trigger>
                  <Clickable
                    fontSize="12px"
                    alignItems="center"
                    gap={1}
                    color="black"
                  >
                    <VeltCommentsSidebarWireframe.Status.Trigger.Name />
                    <VeltCommentsSidebarWireframe.Status.Trigger.Arrow>
                      <DownOutlinedStyled />
                    </VeltCommentsSidebarWireframe.Status.Trigger.Arrow>
                  </Clickable>
                </VeltCommentsSidebarWireframe.Status.Trigger>
                <VeltCommentsSidebarWireframe.Status.Content>
                  <VeltCommentsSidebarWireframe.Status.Content.Item>
                    <Clickable
                      alignItems="top"
                      gap={1}
                      m={1}
                      p={1}
                      fontSize="12px"
                      color="black"
                    >
                      <VeltCommentsSidebarWireframe.Status.Content.Item.Icon />
                      <VeltCommentsSidebarWireframe.Status.Content.Item.Name />
                      <VeltCommentsSidebarWireframe.Status.Content.Item.Checkbox />
                    </Clickable>
                  </VeltCommentsSidebarWireframe.Status.Content.Item>
                </VeltCommentsSidebarWireframe.Status.Content>
              </VeltCommentsSidebarWireframe.Status>
            </Flex>
          </VeltCommentsSidebarWireframe.Header>

          <VeltCommentsSidebarWireframe.EmptyPlaceholder />
          <VeltCommentsSidebarWireframe.List>
            <VeltCommentsSidebarWireframe.List.Item>
              <VeltCommentsSidebarWireframe.List.Item.Group />
              <VeltCommentsSidebarWireframe.List.Item.DialogContainer />
            </VeltCommentsSidebarWireframe.List.Item>
          </VeltCommentsSidebarWireframe.List>

          <Flex justifyContent="center" width="100%">
            <VeltCommentsSidebarWireframe.PageModeComposer />
          </Flex>
        </Flex>
      </VeltCommentsSidebarWireframe.Panel>
    </VeltCommentsSidebarWireframe>

    <VeltCommentsSidebarWireframe variant="locked">
      <VeltCommentsSidebarWireframe.Skeleton />
      <VeltCommentsSidebarWireframe.Panel>
        <Flex
          height="100%"
          width="100%"
          backgroundColor="white"
          border="none"
          flexDirection="column"
          padding="0"
        >
          <VeltCommentsSidebarWireframe.Header>
            <Flex alignItems="center" justifyContent="space-between">
              <Flex alignItems="center">
                <Box fontSize="16px" fontWeight={500} mr={3}>
                  Comments
                </Box>
                <Flex fontSize="12px" alignItems="center" gap={0.5}>
                  <SearchOutlined />
                  <VeltCommentsSidebarWireframe.Search />
                </Flex>
              </Flex>
            </Flex>
          </VeltCommentsSidebarWireframe.Header>

          <VeltCommentsSidebarWireframe.EmptyPlaceholder />
          <VeltCommentsSidebarWireframe.List>
            <VeltCommentsSidebarWireframe.List.Item>
              <VeltCommentsSidebarWireframe.List.Item.Group />
              <VeltCommentsSidebarWireframe.List.Item.DialogContainer>
                <VeltCommentDialogWireframe>
                  <VeltCommentDialogWireframe.Header>
                    <div className="locked-variant-status">
                      <CheckCircleOutlined />
                      <VeltData path="commentAnnotation.status.name" />
                    </div>
                  </VeltCommentDialogWireframe.Header>
                  <VeltCommentDialogWireframe.Body>
                    <VeltCommentDialogWireframe.Threads>
                      <VeltCommentDialogWireframe.ThreadCard>
                        <Grid
                          gridTemplateColumns="auto 1fr auto"
                          alignItems="start"
                        >
                          <Box pr={4}>
                            <VeltCommentDialogWireframe.ThreadCard.Avatar />
                          </Box>
                          <Box>
                            <Box display="inline">
                              <BoldAndCapitalize>
                                <VeltCommentDialogWireframe.ThreadCard.Name />
                                <VeltCommentDialogWireframe.ThreadCard.Unread />
                              </BoldAndCapitalize>
                              <VeltIf condition="{comment.commentId} === {commentAnnotation.comments[0].commentId} && {commentAnnotation.context.commentType} === 'Cell'">
                                <span className="commented-in-text">
                                  {' '}
                                  commented in{' '}
                                </span>

                                <Underline className="commented-in-text">
                                  <VeltData path="commentAnnotation.context.underlinedText" />
                                </Underline>
                              </VeltIf>
                            </Box>
                            <Box>
                              <VeltCommentDialogWireframe.ThreadCard.Time />
                            </Box>
                            <Box mb={2}>
                              <VeltCommentDialogWireframe.ThreadCard.Message />
                              <VeltIf condition="{commentAnnotation.comments.length} > 1">
                                <VeltCommentDialogWireframe.ToggleReply />
                              </VeltIf>
                            </Box>
                          </Box>
                        </Grid>
                      </VeltCommentDialogWireframe.ThreadCard>
                    </VeltCommentDialogWireframe.Threads>
                  </VeltCommentDialogWireframe.Body>
                </VeltCommentDialogWireframe>
              </VeltCommentsSidebarWireframe.List.Item.DialogContainer>
            </VeltCommentsSidebarWireframe.List.Item>
          </VeltCommentsSidebarWireframe.List>

          <Flex justifyContent="center" width="100%">
            <VeltCommentsSidebarWireframe.PageModeComposer />
          </Flex>
        </Flex>
      </VeltCommentsSidebarWireframe.Panel>
    </VeltCommentsSidebarWireframe>
  </>
);

export const CommentsSidebarStyles = createGlobalStyle`
.velt-comment-dialog-overlay-panel {
  .velt-comment-sidebar-navigation-button {
    display: none !important;
  }
}

.groups-list-comments-drawer {
  .velt-comment-dialog {
    box-shadow: none !important;
  }
  .velt-comment-thread {
    width: 100% !important;
  }
  
  .velt-comment-dialog {
    width: 100% !important;
    border-radius: 0 !important;
  }
  .velt-body {
    margin-top: 16px !important;
  }
}

.velt-comment-dialog {
  border-radius: var(--velt-border-radius-lg) !important;
}

.snippyly-menu .mat-mdc-menu-content {
  border-radius: var(--velt-border-radius-lg) !important;
}

.velt-assignee-banner--heading {
  border-radius: none !important;
  border-top-left-radius: var(--velt-border-radius-lg) !important;
  border-top-right-radius: var(--velt-border-radius-lg) !important;
}

.velt-sidebar-container {
    width: 100% !important;
    height: 100% !important;
  }

  .velt-comment-sidebar-navigation-button {
    border: none !important;
    display: flex !important;
    color: ${({ theme }) => theme.colors.text.default} !important;
  }

  .velt-comment-dialog.velt-comment-dialog--sidebar-mode, .groups-list-comments-drawer {
    .velt-assignee-banner--heading {
      border-top-left-radius: 0px !important;
      border-top-right-radius: 0px !important;
    }
    border-color: var(--velt-border-5) !important;
    border-radius: 0px !important;
    .commented-in-text {
      display: inline;

      &:hover {
        cursor: pointer;
      }
    }
  }
  .velt-status-dropdown-trigger {
    background-color: #F0F5FF !important;
    padding-left: 4px !important;
    padding-right: 4px !important;
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }
  .velt-sidebar--comments-hr {
    display: none !important;
  }
  .velt-thread-card--message {
    padding-left: 0 !important;
  }
  .velt-toggle-reply--button {
    padding-left: 0 !important;
    color: ${({ theme }) => theme.colors.text.secondary} !important;
  }
  .velt-sidebar-search--input {
    font-size: 12px !important;
    color: ${({ theme }) => theme.colors.text.secondary} !important;
  }

  .velt-assignee-banner--user-name, .velt-assignee-banner--text {
    color: ${({ theme }) => theme.colors.text.default} !important;
  }
  .velt-composer--submit-button {
     background: var(--velt-light-mode-green) !important;
    &:hover {
      background: ${({ theme }) => theme.colors.primary.hover} !important;
    }
  }
  .velt-sidebar-page-mode-composer {
    width: 450px !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .modal-div.velt-comment-dialog.velt-comment-dialog--no-comments {
    width: 100% !important;
    box-shadow: none !important;
    // The default Velt width - needed for popover comments
    min-width: 361px !important;
    border: none !important;
    
    .velt-composer--reply-div {
      padding: 0.5rem !important;
    }
  }
  [data-velt-comment-dialog-comments-status="IN_PROGRESS"] {
    .velt-assignee-banner--heading {
      background-color: ${({ theme }) =>
        theme.velt.inProgressStatus} !important;
    }
  }
  [data-velt-comment-dialog-comments-status="RESOLVED"] {
    .velt-assignee-banner--heading {
      background-color: ${({ theme }) => theme.velt.resolvedStatus} !important;
    }
  }
  [data-velt-comment-dialog-comments-status="OPEN"] {
    .velt-assignee-banner--heading {
      background-color: ${({ theme }) => theme.velt.openStatus} !important;
    }
  }

  app-comment-sidebar-list {
    scrollbar-gutter: stable !important;
    padding-right: 8px !important;
  }

  .commented-in-text {
    display: none;
  }

  .locked-variant-status {
    color: ${({ theme }) => theme.velt.resolvedStatusColor} !important;
    background-color: ${({ theme }) => theme.velt.statusBackground} !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    padding: 6px 10px !important;
    margin-bottom: 12px !important;
    width: fit-content !important;
    display: flex !important;
    align-items: center !important;
    gap: 6px !important;
  }
}
`;
