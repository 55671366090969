import React, { useCallback, useEffect, useState } from 'react';
import { gql } from '@apollo/client';
import {
  BarChartOutlined,
  BellOutlined,
  CalendarOutlined,
  DotChartOutlined,
  HomeOutlined,
  LineChartOutlined,
  NotificationOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
  StockOutlined,
  TeamOutlined,
  UserOutlined
} from '@ant-design/icons';
import { nanoid } from 'nanoid';
import { useUnreadNotificationsCount } from '@veltdev/react';
import { useSetAtom } from 'jotai';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useCurrentProperty } from '../../../../hooks/useCurrentProperty';
import { useUserPermissionsQuery } from '../../../../__generated__/graphql';
import { RestrictionsIcon } from '../../../../theme/icons/RestrictionsIcon';
import { useCalendarView } from '../../../../pages/Calendar/hooks/useCalendarView';
import { useRestrictionsNavigation } from '../../../../pages/Restrictions/ListStrategicRestrictions/hooks/useRestrictionsNavigation';
import { UserDropdown } from './UserDropdown';
import { IntercomButton } from './IntercomButton';
import { MenuItems, SideNavigation } from './SideNavigation';
import { filterDefined } from '../../../../utils';
import { FeatureFlags, useFlag } from '../../../../hooks/useFlag';
import paceLocalStorage from '../../../../utils/paceLocalStorage';
import { useKeyDown } from '../../../../hooks/useKeyDown';
import { showNotificationsAtom } from '../../../../store/showNotificationsAtom';
import { TrackingEventNames } from '../../../../tracking/trackingNames';
import { useTrackEvent } from '../../../../hooks/useTrackEvent';

export const MenuItemLabel = styled.span`
  font-weight: 500;
`;

export const MenuItemLink = styled(Link)`
  font-weight: 500;
`;

gql`
  query UserPermissions {
    permissions {
      id
      showConfigurationCentre
    }
  }
`;

export const SideNavigationContainer = () => {
  const setShowNotifications = useSetAtom(showNotificationsAtom);
  const newRestrictionFramework = useFlag(
    FeatureFlags.NewRestrictionsFramework
  );
  const showLegacyInsights = useFlag(FeatureFlags.ShowLegacyInsights);
  const [showUserDropdown, setShowUserDropdown] = useState(false);
  const [collapsed, setCollapsed] = useState(
    paceLocalStorage.getCollapsedSideNavigation()
  );
  const { forYou: unreadNotificationsCount } = useUnreadNotificationsCount();
  const userHasNotifications =
    unreadNotificationsCount && unreadNotificationsCount > 0;

  const { data, error } = useUserPermissionsQuery();
  const { id: currentPropertyId } = useCurrentProperty();

  const { view: calendarView } = useCalendarView();
  const { view: restrictionsView } = useRestrictionsNavigation();

  const handleClosePopover = useCallback(() => {
    window.Canny?.('closeChangelog');
  }, []);

  useKeyDown('Escape', handleClosePopover);

  const handleCollapsedChange = useCallback((newState: boolean) => {
    setCollapsed(newState);
    paceLocalStorage.setCollapsedSideNavigation(newState);
  }, []);

  const trackEvent = useTrackEvent();

  useEffect(() => {
    if (data && !error && window.Canny) {
      window.Canny('initChangelog', {
        appID: CANNY_APP_ID,
        position: 'right',
        align: 'bottom',
        theme: 'light'
      });
    }
  }, [data, error, collapsed]);

  if (error) {
    throw new Error(`Unable to load permissions ${error}`);
  }

  const topItems: MenuItems = data?.permissions
    ? filterDefined([
        {
          icon: <HomeOutlined />,
          label: <MenuItemLink to="/">Home</MenuItemLink>,
          key: '/'
        },
        {
          icon: <StockOutlined />,
          label: (
            <MenuItemLink to={`/optimization?property=${currentPropertyId}`}>
              Optimization
            </MenuItemLink>
          ),
          key: `/optimization?property=${currentPropertyId}`
        },
        {
          icon: <CalendarOutlined />,
          label: (
            <MenuItemLink
              to={`/calendar/${calendarView}?property=${currentPropertyId}`}
            >
              Calendar
            </MenuItemLink>
          ),
          key: `/calendar/${calendarView}?property=${currentPropertyId}`
        },
        !newRestrictionFramework
          ? {
              icon: <RestrictionsIcon />,
              label: (
                <MenuItemLink
                  to={`/restrictions/${restrictionsView}?property=${currentPropertyId}`}
                >
                  Restrictions
                </MenuItemLink>
              ),
              key: `/restrictions/${restrictionsView}?property=${currentPropertyId}`
            }
          : undefined,
        {
          icon: <TeamOutlined />,
          label: <MenuItemLink to="/groups">Groups</MenuItemLink>,
          key: '/groups'
        },
        {
          icon: <BarChartOutlined />,
          label: (
            <MenuItemLink
              to={`/planning/forecasts/rolling?property=${currentPropertyId}`}
            >
              Planning
            </MenuItemLink>
          ),
          key: `/planning/forecasts/rolling?property=${currentPropertyId}`
        },
        showLegacyInsights
          ? {
              icon: <LineChartOutlined />,
              label: <MenuItemLink to="/insights">Insights</MenuItemLink>,
              key: '/insights'
            }
          : null,

        {
          icon: <DotChartOutlined />,
          label: (
            <MenuItemLink
              to={{
                pathname: `/insights-v2`,
                state: {
                  key: nanoid()
                }
              }}
            >
              Insights 2.0
            </MenuItemLink>
          ),
          key: '/insights-v2'
        }
      ])
    : [];

  const bottomItems: MenuItems = filterDefined([
    {
      icon: <UserOutlined />,
      label: (
        <UserDropdown
          open={showUserDropdown}
          onOpenChange={setShowUserDropdown}
        >
          <MenuItemLabel>My Account</MenuItemLabel>
        </UserDropdown>
      ),
      title: 'My Account',
      key: 'My Account',
      onClick: () => setShowUserDropdown(!showUserDropdown)
    },
    {
      icon: <QuestionCircleOutlined />,
      label: (
        <IntercomButton>
          <MenuItemLabel>Help</MenuItemLabel>
        </IntercomButton>
      ),
      key: 'Help'
    },
    window.Canny
      ? {
          icon: <NotificationOutlined />,
          label: <MenuItemLabel>Releases</MenuItemLabel>,
          title: 'Releases',
          key: 'Releases',
          'data-canny-changelog': !collapsed
        }
      : undefined,
    data?.permissions?.showConfigurationCentre
      ? {
          icon: <SettingOutlined />,
          label: <MenuItemLink to="/configuration">Settings</MenuItemLink>,
          key: '/configuration'
        }
      : undefined,
    {
      label: <MenuItemLabel>Notifications</MenuItemLabel>,
      icon: <BellOutlined />,
      title: 'Notifications',
      key: 'Notifications',
      className: userHasNotifications ? 'has-notifications' : '',
      onClick: () => {
        setShowNotifications(v => !v);
        trackEvent({
          name: TrackingEventNames.collaboration.notificationPanelOpened
        });
      }
    }
  ]);

  return (
    <SideNavigation
      topItems={topItems}
      bottomItems={bottomItems}
      collapsed={collapsed}
      onSetCollapsed={handleCollapsedChange}
    />
  );
};
