import React, { useEffect, useMemo } from 'react';
import { Redirect, Route, useLocation, Switch } from 'react-router-dom';
import { Button } from 'antd';
import { useSetAtom } from 'jotai';
import { PageContent } from '../../../components/PageContent';
import { Box, Flex, TreeMultiSelect } from '../../../theme/components';
import { useSelectedPropertyIds } from '../../../hooks/useSelectedPropertyIds';
import { AccessLevel } from '../../../__generated__/graphql';
import { usePropertiesList } from '../../../hooks/usePropertiesList';
import { TreeMultiSelectOption } from '../../../theme/components/TreeMultiSelect';
import { RateTypesTab } from './components/RateTypesTab/RateTypesTab';
import { rateTypeDrawerParamsAtom } from './store';
import { filterDefined } from '../../../utils';
import { usePermissions } from '../../../hooks/usePermissions';
import { useCurrentUser } from '../../../hooks/useCurrentUser';

enum Routes {
  RateTypes = '/configuration/rate-types',
  Mapping = '/configuration/rate-types/mapping'
}

export const RateTypes = () => {
  const currentUser = useCurrentUser();
  const { isAdmin } = usePermissions();

  const setRateTypeDrawerParams = useSetAtom(rateTypeDrawerParamsAtom);
  const [selectedPropertyIds, setSelectedPropertyIds] =
    useSelectedPropertyIds();

  const { properties = [], propertiesIds = [] } = usePropertiesList(
    AccessLevel.Full
  );

  const location = useLocation();
  const isSelectedViewRateTypes = useMemo(
    () => location.pathname === Routes.RateTypes,
    [location.pathname]
  );

  const actionButton = useMemo(
    () =>
      isSelectedViewRateTypes ? (
        <Button type="primary" onClick={() => setRateTypeDrawerParams({})}>
          New Rate Type
        </Button>
      ) : (
        <Button type="primary">Bulk Edit</Button>
      ),
    [isSelectedViewRateTypes, setRateTypeDrawerParams]
  );

  useEffect(() => {
    if (!isSelectedViewRateTypes && !selectedPropertyIds.length) {
      setSelectedPropertyIds(propertiesIds);
    }
  }, [
    isSelectedViewRateTypes,
    propertiesIds,
    selectedPropertyIds.length,
    setSelectedPropertyIds
  ]);

  const mapToTreeMultiSelect: TreeMultiSelectOption[] = propertiesIds?.map(
    i => ({ key: i, title: properties.find(p => p.id === i)?.name, value: i })
  );

  if (!currentUser) {
    return null;
  }

  return (
    <PageContent
      title="Rate Types"
      headerRight={
        !isSelectedViewRateTypes && (
          <Box width={275}>
            <TreeMultiSelect
              onChange={setSelectedPropertyIds}
              selected={selectedPropertyIds}
              selectedLabel={['Property', 'Properties']}
              flattenSelection
              virtual={false}
              aria-label="Selected properties"
              options={mapToTreeMultiSelect}
            />
          </Box>
        )
      }
      tabs={filterDefined([
        isAdmin()
          ? {
              to: Routes.RateTypes,
              exact: true,
              children: 'Rate Types'
            }
          : undefined,
        {
          to: Routes.Mapping,
          children: 'Mapping'
        }
      ])}
      tabsExtra={actionButton}
    >
      <Flex flexDirection="column" height="100%">
        <Switch>
          {isAdmin() && (
            <Route exact path={Routes.RateTypes}>
              <RateTypesTab />
            </Route>
          )}
          <Route path={Routes.Mapping}>Mapping</Route>
          <Redirect to={Routes.Mapping} />
        </Switch>
      </Flex>
    </PageContent>
  );
};
