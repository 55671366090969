import styled from 'styled-components';
import { ColumnsType } from 'antd/lib/table';
import sortBy from 'lodash/sortBy';
import React, { useMemo, useState } from 'react';
import { useSetAtom } from 'jotai';
import { EllipsisOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import produce from 'immer';
import { Button, Dropdown, Popconfirm } from 'antd';
import { notExpandableClassName, StyledTable } from '../shared.styles';
import { NestedRateType, RateType } from '../../types';
import {
  DeviationType,
  useDeleteRateTypeMutation,
  useRateTypesQuery
} from '../../../../../__generated__/graphql';
import { filterDefined, formatPercentage } from '../../../../../utils';
import { CustomExpandIcon } from '../../../../../theme/icons';
import { PropertiesListPopover } from './PropertiesListPopoverContent';
import { DataRetrievalError } from '../../../../../components/DataRetrievalError';
import { Box } from '../../../../../theme/components';
import { RateTypeDrawer } from './RateTypeDrawer/RateTypeDrawer';
import { policyReadableNameMap } from './shared';
import { rateTypeDrawerParamsAtom } from '../../store';
import { usePermissions } from '../../../../../hooks/usePermissions';
import formatter from '../../../../../utils/formatter';

const StyledWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const DeviationColumnWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const BaseRateTag = styled.div<{ isBaseRate: boolean }>`
  font-size: 12px;
  padding: 2px 8px;
  border-radius: 2px;
  display: inline;
  border: 1px solid ${props => props.theme.tag.rateType};
  background-color: ${props =>
    props.isBaseRate ? props.theme.tag.rateType : 'white'};
  color: ${props => (props.isBaseRate ? 'white' : props.theme.tag.rateType)};
`;

const RedExclamationCircleOutlined = styled(ExclamationCircleOutlined)`
  color: ${props => props.theme.colors.error.default} !important;
  width: 16px;
  margin-right: 5px;
`;

const formatDeviation = (
  deviationAmount: string,
  deviationType: DeviationType
) => {
  if (deviationType === DeviationType.Absolute) {
    return formatter.format(deviationAmount, true);
  }

  return formatPercentage(Number(deviationAmount) / 100, 2, {
    minimumFractionDigits: 0
  });
};

export const RateTypesTab = () => {
  const { isSystemAdmin } = usePermissions();
  const setRateTypeDrawerParams = useSetAtom(rateTypeDrawerParamsAtom);

  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

  const [deleteRateType] = useDeleteRateTypeMutation();
  const { loading, data, error, refetch } = useRateTypesQuery({
    onCompleted: data => {
      const rateTypes = data.propertyGroup.rateTypes;
      const sortedRateTypes = sortBy(rateTypes, 'name');

      if (sortedRateTypes[0]) {
        setExpandedRowKeys([sortedRateTypes[0].id]);
      }
    }
  });

  const rateTypes = useMemo(() => {
    if (!data?.propertyGroup.rateTypes.length) {
      return [];
    }

    const baseRateTypes: NestedRateType[] = data.propertyGroup.rateTypes.filter(
      rateType => rateType.isBase
    );

    const childRateTypes =
      data?.propertyGroup.rateTypes.filter(rateType => !rateType.isBase) || [];

    const mappedRateType = produce(baseRateTypes, baseRateTypesDraft => {
      for (const childRateType of childRateTypes) {
        const parentRateType = baseRateTypesDraft.find(
          rateType => rateType.id === childRateType.derivedFrom
        );

        if (parentRateType) {
          if (!parentRateType.children) {
            parentRateType.children = [childRateType];
          } else {
            parentRateType.children.push(childRateType);
          }
        }
      }
    });

    return mappedRateType;
  }, [data?.propertyGroup.rateTypes]);

  const columns = useMemo<ColumnsType<RateType>>(
    () => [
      {
        title: 'Rate Type Name',
        key: 'name',
        width: '300px',
        dataIndex: 'name',
        sortDirections: ['ascend', 'descend', 'ascend'],
        defaultSortOrder: 'ascend',
        sorter: (a, b) => a.name.localeCompare(b.name)
      },
      {
        title: 'Type',
        key: 'type',
        width: '250px',
        render: (_, rateType) => (
          <BaseRateTag isBaseRate={rateType.isBase}>
            {rateType.isBase ? 'Base Rate' : 'Dependent Rate'}
          </BaseRateTag>
        )
      },
      {
        title: 'Policy',
        key: 'policy',
        render: (_, rateType) => {
          if (!rateType.policy) {
            return;
          }

          return <div>{policyReadableNameMap[rateType.policy]}</div>;
        }
      },
      {
        title: 'No of Properties',
        key: 'number-of-properties',
        render: (_, rateType) => (
          <PropertiesListPopover properties={rateType.properties} />
        )
      },
      {
        title: 'Deviation',
        key: 'deviation',
        render: (_, rateType) => {
          const dropdown = (
            <Dropdown
              trigger={['click']}
              menu={{
                items: filterDefined([
                  {
                    key: 'edit',
                    label: 'Edit',
                    onClick: () =>
                      setRateTypeDrawerParams({
                        ...rateType,
                        propertyIds: rateType.properties?.map(p => p.id)
                      })
                  },
                  isSystemAdmin()
                    ? {
                        key: 'delete',
                        label: (
                          <Popconfirm
                            key="delete-confirm"
                            overlayStyle={{ width: 300 }}
                            title="Are you sure you want to delete this Rate Type?"
                            onCancel={e => e?.stopPropagation()}
                            okText="Delete"
                            icon={<RedExclamationCircleOutlined />}
                            arrow={false}
                            onConfirm={async () => {
                              await deleteRateType({
                                variables: {
                                  rateTypeId: rateType.id
                                }
                              });
                              await refetch();
                            }}
                          >
                            <div>Delete</div>
                          </Popconfirm>
                        )
                      }
                    : undefined
                ])
              }}
              placement="bottomRight"
            >
              <Button type="text" icon={<EllipsisOutlined rotate={90} />} />
            </Dropdown>
          );

          if (rateType.deviationAmount == null || !rateType.deviationType) {
            return (
              <DeviationColumnWrapper>
                <div />
                {dropdown}
              </DeviationColumnWrapper>
            );
          }

          return (
            <DeviationColumnWrapper>
              <div>
                {formatDeviation(
                  rateType.deviationAmount,
                  rateType.deviationType
                )}
              </div>
              {dropdown}
            </DeviationColumnWrapper>
          );
        }
      }
    ],
    [deleteRateType, isSystemAdmin, refetch, setRateTypeDrawerParams]
  );

  if (error) {
    return (
      <Box pr={3}>
        <DataRetrievalError dataDescription="Rate Types" />
      </Box>
    );
  }

  return (
    <>
      <StyledWrapper>
        <StyledTable<NestedRateType>
          rowClassName={rateType =>
            !rateType.children?.length ? notExpandableClassName : ''
          }
          expandable={{
            expandedRowKeys,
            expandIcon: CustomExpandIcon,
            onExpandedRowsChange: keys => setExpandedRowKeys(keys as string[])
          }}
          pagination={false}
          loading={loading}
          dataSource={rateTypes}
          columns={columns}
          sticky
          rowKey={rateType => rateType.id}
        />
      </StyledWrapper>
      <RateTypeDrawer rateTypes={rateTypes} onSave={refetch} />
    </>
  );
};
