import { atom } from 'jotai';
import { DeviationType, RateTypePolicy } from '../../../__generated__/graphql';

export const rateTypeDrawerParamsAtom = atom<
  | {}
  | {
      id: string;
      baseRatePlan: boolean;
      name: string;
      deviationType: DeviationType;
      deviationAmount: number;
      derivedFrom: string;
      policy: RateTypePolicy;
      propertyIds: string[];
    }
  | undefined
>();
