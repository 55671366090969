import styled from 'styled-components';
import { Form, InputNumber } from 'antd';

const deviationWrapperWidth = 75;

export const SubTitle = styled.div`
  font-weight: 400;
  font-size: ${props => props.theme.typography.fontSize.lg}px;
  color: ${props => props.theme.colors.text.default};
  margin-bottom: 12px;
`;

export const FormItemWithSpaceBetween = styled(Form.Item)`
  width: 100%;
  .ant-form-item-row {
    display: flex;
    justify-content: space-between;
  }

  .ant-form-item-control {
    flex: unset;
  }
`;

export const DeviationTypeWrapper = styled.div`
  display: inline-block;
  min-width: ${deviationWrapperWidth}px;
`;

export const DeviationInput = styled(InputNumber)`
  width: calc(100% - ${deviationWrapperWidth}px);
`;
