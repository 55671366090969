import { ThemeConfig, message } from 'antd';
import theme from '.';
import { ColorGrey80 } from '../tokens/colors';

const token: ThemeConfig['token'] = {
  // Typography
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.fontSize.default,
  fontSizeSM: theme.typography.fontSize.sm,
  fontSizeLG: theme.typography.fontSize.lg,
  fontSizeXL: theme.typography.fontSize.xl,
  fontSizeHeading1: theme.typography.fontSize.h1,
  fontSizeHeading2: theme.typography.fontSize.h2,
  fontSizeHeading3: theme.typography.fontSize.h3,

  // Base
  colorWhite: theme.colors.white,
  colorIcon: theme.colors.icon.default,
  colorIconHover: theme.colors.icon.secondary,
  colorLink: theme.colors.link,

  // Text
  colorText: theme.colors.text.default,
  colorTextSecondary: theme.colors.text.default,
  colorTextLightSolid: theme.colors.white,
  colorTextDisabled: theme.colors.text.disabled,

  // Primary
  colorPrimaryBg: theme.colors.primary.background,
  colorPrimaryBgHover: theme.colors.primary.backgroundHover,
  colorPrimaryBorder: theme.colors.primary.border,
  colorPrimaryBorderHover: theme.colors.primary.borderHover,
  colorPrimaryHover: theme.colors.primary.hover,
  colorPrimary: theme.colors.primary.default,
  colorPrimaryActive: theme.colors.primary.default,
  colorPrimaryTextHover: theme.colors.primary.textHover,
  colorPrimaryText: theme.colors.primary.text,
  colorPrimaryTextActive: theme.colors.primary.textActive,

  // Backgrounds
  colorBgBase: theme.colors.background.base,
  colorBgContainer: theme.colors.background.base,
  colorBgContainerDisabled: theme.colors.background.cotainerDisabled,
  colorBgLayout: theme.colors.background.layout,
  colorBgElevated: theme.colors.background.base,

  // Error
  colorError: theme.colors.error.default,
  colorErrorBorder: theme.colors.error.border,
  colorErrorBg: theme.colors.error.background,

  // Info
  colorInfo: theme.colors.info.default,
  colorInfoBorder: theme.colors.info.border,
  colorInfoBg: theme.colors.info.background,

  // Warning
  colorWarning: theme.colors.warning.default,
  colorWarningBorder: theme.colors.warning.border,
  colorWarningBg: theme.colors.warning.background,

  // Success
  colorSuccess: theme.colors.success.default,
  colorSuccessBorder: theme.colors.success.border,
  colorSuccessBg: theme.colors.success.background,

  // Shadows
  boxShadow: theme.shadow.default,

  // Borders & Dividers
  colorBorder: theme.colors.border.default,

  borderRadiusXS: theme.borderRadius.xs,
  borderRadiusSM: theme.borderRadius.sm,
  borderRadius: theme.borderRadius.default,
  borderRadiusLG: theme.borderRadius.default,

  // Misc / Mess
  controlItemBgActive: theme.colors.primary.backgroundHover,
  controlOutline: theme.colors.primary.backgroundHover
};

const components: ThemeConfig['components'] = {
  Layout: {
    bodyBg: theme.colors.white
  },
  Typography: {
    titleMarginBottom: 0
  },
  Table: {
    headerSortActiveBg: theme.colors.background.tableHeader,
    bodySortBg: theme.colors.background.base
  },
  Select: {
    multipleItemBg: theme.colors.white
  },
  Badge: {
    dotSize: 8
  },
  Collapse: {
    headerBg: theme.colors.background.base,
    colorBorder: ColorGrey80
  }
};

export const configureGlobalMessage = () =>
  message.config({ top: 75, getContainer: () => document.body });

export default {
  components,
  token
};
