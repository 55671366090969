import React from 'react';
import { Button, ButtonProps } from 'antd';
import { PopupConfirm } from '../PopupConfirm';

interface Props {
  confirm: boolean;
  label: string;
  buttonProps?: Partial<ButtonProps>;
  action?: () => void;
}

export const ConfirmableButton = ({
  confirm,
  label,
  buttonProps,
  action
}: Props) => (
  <>
    {confirm && !buttonProps?.disabled ? (
      <PopupConfirm onConfirm={action}>
        <Button key={label} {...buttonProps}>
          {label}
        </Button>
      </PopupConfirm>
    ) : (
      <Button key={label} onClick={action} {...buttonProps}>
        {label}
      </Button>
    )}
  </>
);
