import React, { useEffect, useMemo, useState } from 'react';
import { useAtom } from 'jotai';
import uniq from 'lodash/uniq';
import orderBy from 'lodash/orderBy';
import { Drawer, Form, Input, message, Segmented, Select, Switch } from 'antd';
import { rateTypeDrawerParamsAtom } from '../../../store';
import { RateTypeDrawerFormType } from './types';
import { FormItemLabelWithIcon } from './FormItemLabelWithIcon';
import {
  AccessLevel,
  DeviationType,
  RateTypePolicy,
  useCreateRateTypeMutation,
  useUpdateRateTypeMutation
} from '../../../../../../__generated__/graphql';
import { ActionFooter } from '../../../../../../components/ActionFooter';
import { policyReadableNameMap } from '../shared';
import { NestedRateType } from '../../../types';
import { usePropertiesList } from '../../../../../../hooks/usePropertiesList';
import { TreeMultiSelect } from '../../../../../../theme/components';
import {
  SubTitle,
  FormItemWithSpaceBetween,
  DeviationTypeWrapper,
  DeviationInput
} from './RateTypeDrawer.styles';
import { formatGraphqlErrorMessage } from '../../../../../../utils';

const minPercentage = -99.99;

type Props = {
  rateTypes: NestedRateType[];
  onSave: () => void;
};

export const RateTypeDrawer = ({ rateTypes, onSave }: Props) => {
  const { properties } = usePropertiesList(AccessLevel.Full);
  const [saving, setSaving] = useState(false);
  const [rateTypeDrawerParams, setRateTypeDrawerParams] = useAtom(
    rateTypeDrawerParamsAtom
  );

  const [form] = Form.useForm<RateTypeDrawerFormType>();
  const isBase = Form.useWatch('isBase', form);
  const deviationType = Form.useWatch('deviationType', form);
  const deviationAmount = Form.useWatch('deviationAmount', form);

  const [createRateType] = useCreateRateTypeMutation();
  const [updateRateType] = useUpdateRateTypeMutation();

  const isEditDrawer = rateTypeDrawerParams && 'id' in rateTypeDrawerParams;
  const editedRateTypeId = isEditDrawer ? rateTypeDrawerParams.id : undefined;

  const minDeviationValue = useMemo(() => {
    if (deviationType === DeviationType.Percentage) {
      return minPercentage;
    }

    return undefined;
  }, [deviationType]);

  const drawerTitle = useMemo(() => {
    if (!rateTypeDrawerParams) {
      return '';
    }

    if (isEditDrawer) {
      return 'Edit Rate Type';
    }

    return 'Create Rate Type';
  }, [isEditDrawer, rateTypeDrawerParams]);

  const derivedFromOptions = useMemo(
    () =>
      orderBy(rateTypes, 'name').map(rt => ({
        label: rt.name,
        value: rt.id,
        // A maximum of 5 rate types/plans can be defined per property (Base + 4 Derived).
        disabled: rt.children && rt.children?.length >= 4
      })),
    [rateTypes]
  );

  const propertyIdsUsedInOtherRateTypes = uniq(
    rateTypes
      .filter(rt => rt.id !== editedRateTypeId)
      .flatMap(rt => rt.properties?.map(p => p.id) || [])
  );

  const propertiesOptions = useMemo(
    () =>
      properties?.map(property => ({
        key: property.id,
        title: property.name,
        value: property.id,
        // A property can be used only in 1 Rate Type
        disabled: propertyIdsUsedInOtherRateTypes.includes(property.id)
      })) || [],
    [properties, propertyIdsUsedInOtherRateTypes]
  );

  const handleSave = async () => {
    setSaving(true);

    try {
      const values = await form.validateFields();
      const inputVariables = {
        isBase: values.isBase,
        name: values.name,
        derivedFrom: values.isBase ? undefined : values.derivedFrom,
        deviationAmount: values.isBase
          ? undefined
          : values.deviationAmount?.toString() || '0',
        deviationType: values.isBase ? undefined : values.deviationType,
        policy: values.policy ?? undefined,
        propertyIds: values.isBase ? values.propertyIds : undefined
      };

      if (isEditDrawer) {
        await updateRateType({
          variables: {
            input: {
              id: rateTypeDrawerParams.id,
              ...inputVariables
            }
          }
        });
      } else {
        await createRateType({
          variables: {
            input: inputVariables
          }
        });
      }

      setRateTypeDrawerParams(undefined);
      onSave();
    } catch (error) {
      const formattedError = formatGraphqlErrorMessage([error as Error]);
      if (formattedError) {
        message.destroy();
        message.error(formattedError);
      }
    } finally {
      setSaving(false);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    setRateTypeDrawerParams(undefined);
  };

  useEffect(() => {
    if (!rateTypeDrawerParams) {
      form.resetFields();
    } else if (isEditDrawer) {
      form.setFieldsValue({
        ...rateTypeDrawerParams,
        propertyIds: rateTypeDrawerParams.propertyIds || [],
        deviationType:
          rateTypeDrawerParams.deviationType || DeviationType.Percentage
      });
    }
  }, [form, isEditDrawer, rateTypeDrawerParams]);

  return (
    <Drawer
      open={!!rateTypeDrawerParams}
      title={drawerTitle}
      width={500}
      onClose={() => setRateTypeDrawerParams(undefined)}
    >
      <Form<RateTypeDrawerFormType>
        initialValues={{
          isBase: false,
          propertyIds: [],
          deviationType: DeviationType.Percentage
        }}
        className="pace-antd-form"
        labelCol={{
          span: 7
        }}
        labelAlign="left"
        form={form}
        colon={false}
        requiredMark={false}
      >
        <SubTitle>Details</SubTitle>
        <FormItemWithSpaceBetween
          name="isBase"
          label={
            <FormItemLabelWithIcon
              label="Base Rate Plan"
              tooltip="Enable Base Rate to create an independent rate to derive other rate types from. "
            />
          }
        >
          <Switch disabled={isEditDrawer} />
        </FormItemWithSpaceBetween>
        <Form.Item
          required
          label="Name"
          name="name"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item hidden={isBase} label="Deviation">
          <DeviationTypeWrapper>
            <Form.Item noStyle name="deviationType">
              <Segmented
                value={deviationType}
                options={[
                  {
                    label: '%',
                    value: DeviationType.Percentage
                  },
                  {
                    label: '€',
                    value: DeviationType.Absolute
                  }
                ]}
                onChange={value => {
                  form.setFieldValue('deviationType', value);
                  if (value === DeviationType.Percentage) {
                    form.setFieldValue(
                      'deviationAmount',
                      deviationAmount < minPercentage
                        ? minPercentage
                        : deviationAmount
                    );
                  }
                }}
              />
            </Form.Item>
          </DeviationTypeWrapper>

          <Form.Item noStyle name="deviationAmount">
            <DeviationInput
              addonBefore={deviationType === DeviationType.Absolute ? '€' : '%'}
              min={minDeviationValue}
            />
          </Form.Item>
        </Form.Item>
        <Form.Item
          hidden={isBase}
          required
          label="Derived from"
          name="derivedFrom"
          rules={[{ required: !isBase }]}
        >
          <Select options={derivedFromOptions} />
        </Form.Item>
        <Form.Item
          label={
            <FormItemLabelWithIcon
              label="Policy"
              tooltip="Associate a policy with your rate to ensure accurate comparisons across rate types in the Market Rates tab within Quick View. Learn more how FLYR uses the policy information."
            />
          }
          name="policy"
        >
          <Select
            allowClear
            options={[
              RateTypePolicy.FullyFlexible,
              RateTypePolicy.SemiFlexible,
              RateTypePolicy.NonRefundable
            ].map(value => ({
              label: policyReadableNameMap[value],
              value
            }))}
          />
        </Form.Item>
        <Form.Item
          required
          hidden={!isBase}
          label="Properties"
          name="propertyIds"
          rules={[{ required: isBase }]}
          valuePropName="selected"
        >
          <TreeMultiSelect
            options={propertiesOptions}
            selected={[]}
            disabled={!properties}
            loading={!properties}
            selectedLabel={['Property', 'Properties']}
            flattenSelection
            customLabels={{
              selectAllLabel: 'All Properties'
            }}
          />
        </Form.Item>
      </Form>

      <ActionFooter
        action={isEditDrawer ? 'Save Rate Type' : 'Create Rate Type'}
        undo="Cancel"
        isActionLoading={saving}
        confirmUndo={false}
        onClick={handleSave}
        onUndo={handleCancel}
        isActionDisabled={false}
        errorMessage={undefined}
      />
    </Drawer>
  );
};
