import React from 'react';
import QuestionCircleOutlined from '@ant-design/icons/QuestionCircleOutlined';
import { Popconfirm, PopconfirmProps } from 'antd';

type PopUpConfirmProps = React.PropsWithChildren<{
  title?: PopconfirmProps['title'];
  okText?: PopconfirmProps['okText'];
  icon?: PopconfirmProps['icon'];
  onConfirm?: PopconfirmProps['onConfirm'];
}>;

export const PopupConfirm = ({
  children,
  onConfirm,
  okText = 'Yes',
  title = 'Are you sure?',
  icon = <QuestionCircleOutlined style={{ color: 'red' }} />
}: PopUpConfirmProps) => (
  <Popconfirm {...{ okText, onConfirm, title, icon }}>{children}</Popconfirm>
);
