import styled, { createGlobalStyle } from 'styled-components';
import { TreeSelect } from 'antd';
import {
  ColorGreen100,
  ColorGreen50,
  ColorGrey70,
  ColorWhiteDefault
} from '../../tokens/colors';

export const StyledTreeSelect = styled(TreeSelect)`
  width: 100%;

  & .ant-select-selection-item {
    background: unset;
    border: unset;
    margin-bottom: 0;
    height: unset;
    padding-inline-start: 6px;
  }

  &.ant-select-open .ant-select-selection-overflow-item-rest {
    width: 7px;
  }
` as typeof TreeSelect;

export const GlobalStyle = createGlobalStyle<{ isNested: boolean | undefined }>`
  .pace-tree-select-popup {
      padding: 4px 0;
    
    .ant-select-tree {
      .ant-select-tree-treenode {
        padding: 4px;
        margin-bottom: 0px;
      }

      .ant-select-tree-treenode-checkbox-checked.ant-select-tree-treenode:hover,
      .ant-select-tree-treenode.ant-select-tree-treenode-checkbox-checked.ant-select-tree-treenode-active {
        background-color: #c8eee7;
      }

      .ant-select-tree-treenode:hover,
      .ant-select-tree-treenode.ant-select-tree-treenode-active,
      .ant-select-tree-treenode .ant-select-tree-node-content-wrapper:hover {
        background-color: ${props => props.theme.colors.background.hover};
      }

      .ant-select-tree-node-content-wrapper:hover,
      .ant-select-tree-treenode .ant-select-tree-node-content-wrapper:hover {
        background-color: transparent;
      }

      .ant-select-tree-checkbox:hover .ant-select-tree-checkbox-inner {
        border-color: ${ColorGrey70};
      }

      .ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner::after {
        background-color: white;
        width: 10px;
        height: 2px;
      }

      .ant-select-tree-treenode-checkbox-checked {
        background-color: ${ColorGreen100};
        font-weight: 500;
      }

      .ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner {
        background-color: ${ColorGreen50};
        border-color: ${ColorGreen50};

        &::after {
          background-color: ${ColorWhiteDefault};
          width: 10px;
          height: 2px;
        }
      }

      .ant-select-tree-switcher-noop {
        width: ${props => (props.isNested === false ? '6px' : undefined)}
      }

      .ant-select-tree-treenode.select-all .ant-select-tree-switcher-noop {
        width: 6px;
      }

      .select-all-indeterminate .ant-select-tree-checkbox .ant-select-tree-checkbox-inner {
        background-color: ${ColorGreen50};
        border-color: ${ColorGreen50};

        &::after {
          top: 50%;
          left: 50%;
          background-color: ${ColorWhiteDefault};
          width: 10px;
          height: 2px;
          border: 0;
          transform: translate(-50%, -50%) scale(1);
          opacity: 1;
          content: ' ';
        }
      }
    }

    &.hide-group-checkbox {
      .ant-select-tree-switcher:not(.ant-select-tree-switcher-noop) + .ant-select-tree-checkbox {
        display: none;
      }

      .ant-select-tree-indent + .ant-select-tree-switcher-noop {
        width: 4px;
      }

      // make the hidden-checkbox group label not clickable
      .ant-select-tree-treenode:has(.ant-select-tree-switcher:not(.ant-select-tree-switcher-noop)) .ant-select-tree-node-content-wrapper {
        pointer-events: none;
      }
    }
  }
`;
