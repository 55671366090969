// @ts-strict-ignore
import React, { useState, useMemo } from 'react';
import { TreeSelect, TreeSelectProps, TreeDataNode } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { TreeNode } from 'antd/lib/tree-select';
import classNames from 'classnames';
import {
  getSelectedLeafNodes,
  getSelectableKeys,
  allOptionsSelected
} from './utils';
import { StyledTreeSelect, GlobalStyle } from './TreeMultiSelect.styles';
import { EmptySelectContent } from '..';

export type TreeMultiSelectOption = TreeDataNode & {
  value: string;
  children?: TreeMultiSelectOption[];
};

export type Props = Pick<TreeSelectProps, 'virtual' | 'status'> & {
  options: TreeMultiSelectOption[];
  selected: string[];
  onChange?: (selected: string[]) => void;
  placeholder?: string;
  /**
   * [singular, plural]
   */
  selectedLabel?: [string, string];

  customLabels?: {
    selectAllLabel?: string;
    collapsedLabel?: string;
  };
  /**
   * If true, the selected keys reported by onChange will be
   * a list of all the leaf nodes under selected branches.
   */
  flattenSelection?: boolean;

  /**
   * If options contains a "fabricated" root element such as
   * "All", the key of that element should be provided here.
   */
  rootKey?: string;
  emptySelectMessage?: string;
  /**
   * Set true when you wish to remove the ability to toggle a group as a whole
   */
  hideGroupCheckbox?: boolean;
  popupMatchSelectWidth?: boolean;
  disabled?: boolean;
  loading?: boolean;
};

const SELECT_ALL_VALUE = 'select-all';

const renderTreeNodes = (options: Props['options'], anySelected = false) =>
  options.map(item => {
    if (item.children) {
      return (
        <TreeNode
          role="treeitem"
          value={item.value}
          title={item.title}
          disabled={item.disabled}
          key={item.key}
        >
          {renderTreeNodes(item.children)}
        </TreeNode>
      );
    }

    return (
      <TreeNode
        role="treeitem"
        value={item.value}
        title={item.title}
        key={item.key}
        disabled={item.disabled}
        className={classNames({
          'select-all': item.value === SELECT_ALL_VALUE,
          'select-all-indeterminate':
            item.value === SELECT_ALL_VALUE && anySelected
        })}
      />
    );
  });

export const TreeMultiSelect = ({
  options,
  selected,
  selectedLabel,
  customLabels,
  onChange,
  flattenSelection = false,
  placeholder = 'Please Select',
  virtual,
  emptySelectMessage,
  hideGroupCheckbox = false,
  popupMatchSelectWidth,
  ...props
}: Props) => {
  const [searchValue, setSearchValue] = useState('');
  const [isOpened, setIsOpened] = useState(false);
  const allSelectableKeys = useMemo(
    () => getSelectableKeys(options),
    [options]
  );
  const isNested = options.length
    ? options.some(({ children }) => children?.length > 0)
    : undefined;

  const areAllSelected = useMemo(() => {
    if (flattenSelection) {
      return allOptionsSelected(selected, allSelectableKeys);
    }

    return options.every(option => selected.includes(option.value));
  }, [flattenSelection, options, selected, allSelectableKeys]);

  const selectedLeafs = useMemo(
    () => getSelectedLeafNodes(selected, options),
    [selected, options]
  );
  const isAnySelected = selected.length > 0 && !areAllSelected;

  const handleChange = (value: string[]) => {
    if (flattenSelection) {
      onChange?.(getSelectedLeafNodes(value, options));
    } else {
      onChange?.(value);
    }
  };

  const selectedDisplay = useMemo(() => {
    if (customLabels?.collapsedLabel) {
      return customLabels.collapsedLabel;
    }

    if (areAllSelected) {
      return `All ${selectedLabel[1]} (${selectedLeafs?.length})`;
    }

    const labelIndex = Number(selectedLeafs?.length > 1);
    return `${selectedLeafs?.length} ${selectedLabel[labelIndex]}`;
  }, [
    areAllSelected,
    customLabels?.collapsedLabel,
    selectedLabel,
    selectedLeafs?.length
  ]);

  const selectAllLabel = customLabels?.selectAllLabel
    ? customLabels.selectAllLabel
    : `All ${selectedLabel[1]}`;

  const searchContents = isOpened ? '' : selectedDisplay;

  const hasMultipleOptions =
    options.length > 1 ||
    (options[0]?.children && options[0].children.length > 1);

  const extendedOptions =
    hasMultipleOptions && !searchValue
      ? [
          {
            title: selectAllLabel,
            value: SELECT_ALL_VALUE,
            disabled: options.every(o => o.disabled),
            key: SELECT_ALL_VALUE
          },
          ...options
        ]
      : options;

  const popupClasses = classNames('pace-tree-select-popup', {
    'hide-group-checkbox': hideGroupCheckbox
  });

  return (
    <>
      <GlobalStyle isNested={isNested} />
      <StyledTreeSelect
        popupClassName={popupClasses}
        value={areAllSelected ? [SELECT_ALL_VALUE, ...selected] : selected}
        // We don't want to show selected pills, so we'll always
        // fall back to the "max tags exceeded" placeholder
        maxTagCount={0}
        suffixIcon={isOpened ? <SearchOutlined /> : undefined}
        placeholder={placeholder}
        maxTagPlaceholder={searchContents}
        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
        showSearch
        popupMatchSelectWidth={popupMatchSelectWidth}
        searchValue={searchValue}
        onSearch={setSearchValue}
        onInputKeyDown={e => {
          // disables removing selection with backspace
          if (!searchValue && e.key === 'Backspace') {
            e.stopPropagation();
          }
        }}
        autoClearSearchValue={false}
        notFoundContent={
          <EmptySelectContent description={emptySelectMessage} />
        }
        filterTreeNode={(input, treeNode) => {
          const title = treeNode.props.title.toLowerCase();
          return title.includes(input.toLowerCase());
        }}
        treeCheckable
        showCheckedStrategy={TreeSelect.SHOW_PARENT}
        onChange={(selectedValues: string[], _label, extra) => {
          // clears search value on click of clear icon
          // the trigger value will return the tree node clicked for each checkbox
          // but will not be defined on click of clear icon
          if (selectedValues.length === 0 && !extra.triggerValue) {
            setSearchValue('');
          } else {
            handleChange(selectedValues.filter(v => v !== SELECT_ALL_VALUE));
          }
        }}
        onDeselect={(_, option) => {
          if (option.value === SELECT_ALL_VALUE) {
            handleChange([]);
          }
        }}
        onSelect={(_, option) => {
          if (option.value === SELECT_ALL_VALUE) {
            handleChange(options.filter(o => !o.disabled).map(o => o.value));
          }
        }}
        multiple
        onDropdownVisibleChange={setIsOpened}
        virtual={virtual}
        {...props}
      >
        {renderTreeNodes(extendedOptions, isAnySelected)}
      </StyledTreeSelect>
    </>
  );
};

TreeMultiSelect.getKeys = getSelectableKeys;
