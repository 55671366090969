import styled from 'styled-components';
import ExclamationCircleOutlined from '@ant-design/icons/ExclamationCircleOutlined';
import Text from 'antd/lib/typography/Text';

import { ColorWhiteDefault, ColorGrey100 } from '../../theme/tokens/colors';
import { Box } from '../../theme/components';

export const Footer = styled.div`
  position: absolute;
  background: ${props => props.theme.colors.white};
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
`;

export const Wrapper = styled.div<{ isIndented?: boolean }>`
  background: ${props => (props.isIndented ? ColorGrey100 : ColorWhiteDefault)};
`;

export const StyledExclamationCircleOutlined = styled(
  ExclamationCircleOutlined
)<{
  color: string;
}>`
  color: ${props => props.color};
  margin-right: 12px;
`;

export const StyledText = styled(Text)`
  font-size: 12px;
  line-height: 12px;
`;

export const Spacer = styled.div<{ isIndented?: boolean }>`
  margin-left: ${props => (props.isIndented ? '0.9375rem' : '0rem')};
  margin-right: ${props => (props.isIndented ? '1.4375rem' : '0px')};
  background: white;
`;

export const Container = styled.div<{ errorMessage?: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${props =>
    props.errorMessage ? 'space-between' : 'flex-end'};
  padding: ${props => (props.errorMessage ? '16px 10px' : '12px 17px')};
`;

export const ActionButtonsContainer = styled(Box)`
  flex: 0 0 auto;
`;
