import styled from 'styled-components';
import { Table } from 'antd';

export const notExpandableClassName = 'not-expandable';

export const StyledTable = styled(Table)`
  height: 100%;
  overflow-y: auto;

  td.ant-table-cell {
    vertical-align: middle;
  }

  .ant-table-thead > tr > th {
    background: ${props => props.theme.colors.background.tableHeader};
  }

  td.ant-table-column-sort {
    background: white;
  }

  td .indent-level-1 {
    display: none;
  }

  .${notExpandableClassName} td:first-of-type {
    padding-left: 43px;
  }
` as typeof Table;
