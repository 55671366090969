import { useMemo } from 'react';
import { AccessLevel, UserRole } from '../__generated__/graphql';
import { useCurrentUser } from './useCurrentUser';
import { usePropertiesList } from './usePropertiesList';

export const usePermissions = () => {
  const currentUser = useCurrentUser();
  const { propertiesIds: fullAccessPropertiesIds } = usePropertiesList(
    AccessLevel.Full
  );
  const { propertiesIds: accesiblePropertiesIds } = usePropertiesList(
    AccessLevel.ReadOnly
  );

  return useMemo(
    () => ({
      canWriteProperty: ({ id }: { id?: string }) => {
        if (id) {
          if (!fullAccessPropertiesIds) return false;

          return fullAccessPropertiesIds.some(propertyId => propertyId === id);
        }

        return false;
      },
      canViewProperty: ({ id }: { id?: string }) => {
        if (id) {
          if (!accesiblePropertiesIds) return false;

          return accesiblePropertiesIds.some(propertyId => propertyId === id);
        }

        return false;
      },
      hasFullAccessToAnyProperty: () => {
        if (!fullAccessPropertiesIds) return false;
        const hasFullAccessToAnyProperty = fullAccessPropertiesIds.length > 0;

        return hasFullAccessToAnyProperty;
      },
      isAdmin: () =>
        [UserRole.PaceAdmin, UserRole.PropertyAdmin].some(
          type => type === currentUser?.role
        ),
      isSystemAdmin: () => currentUser?.role === UserRole.PaceAdmin
    }),
    [accesiblePropertiesIds, currentUser?.role, fullAccessPropertiesIds]
  );
};
