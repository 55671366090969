import {
  ColorBlue100,
  ColorBlue40,
  ColorBlue60,
  ColorGreen100,
  ColorGreen50,
  ColorGreen60,
  ColorGrey100,
  ColorGrey20,
  ColorGrey50,
  ColorGrey60,
  ColorGrey70,
  ColorGrey80,
  ColorGrey90,
  ColorLime100,
  ColorLime20,
  ColorLime50,
  ColorRed50,
  ColorRed60,
  ColorRed70,
  ColorWhiteDefault,
  ColorYellow30,
  ColorYellow50,
  ColorYellow70,
  ColorYellow90
} from '../tokens/colors';
import { chartColors } from './chartsTheme';
import { customTheme, plainColors } from './customTheme';
import { extendThemeForStyledSystem } from './styledSystemTheme';

const theme = {
  typography: {
    fontFamily: 'Avenir',
    fontSize: {
      default: 14,
      xs: 10,
      sm: 12,
      lg: 16,
      xl: 18,
      xxl: 20,
      xxxl: 24,
      xxxxl: 28,
      xxxxxl: 32,
      h1: 28,
      h2: 24,
      h3: 20
    }
  },
  colors: {
    white: ColorWhiteDefault,
    icon: {
      default: ColorGrey50,
      secondary: ColorGrey20
    },
    link: ColorGreen50,
    text: {
      default: ColorGrey20,
      secondary: ColorGrey50,
      disabled: ColorGrey60,
      highlight: ColorYellow70
    },
    primary: {
      default: ColorGreen50,
      hover: ColorGreen60,
      background: ColorGreen100,
      backgroundHover: ColorGreen100,
      border: ColorGreen50,
      borderHover: ColorGreen60,
      text: ColorGreen50,
      textHover: ColorGreen60,
      textActive: ColorGreen50,
      light: ColorGreen100
    },
    background: {
      base: ColorWhiteDefault,
      container: ColorGrey90,
      hover: ColorGrey90,
      cotainerDisabled: ColorGrey90,
      layout: ColorGrey90,
      tableHeader: ColorGrey100
    },
    error: {
      border: ColorRed60,
      background: ColorRed70,
      default: ColorRed50
    },
    info: {
      default: ColorBlue60,
      background: ColorBlue100,
      border: ColorBlue40
    },
    warning: {
      default: ColorYellow30,
      background: ColorYellow90,
      border: ColorYellow50
    },
    success: {
      default: ColorLime50,
      background: ColorLime100,
      border: ColorLime20
    },
    border: {
      default: ColorGrey70,
      tableRow: ColorGrey80,
      disabled: ColorGrey90
    },
    badge: {
      alert: ColorRed50,
      filter: ColorGreen50
    },
    plain: plainColors,
    chart: chartColors
  },
  borderRadius: {
    xs: 1,
    sm: 2,
    default: 4,
    lg: 8
  },
  padding: {
    content: 16,
    scrollbar: 8
  },
  shadow: {
    default:
      '0 6px 16px 0 rgba(0, 0, 0, 0.08),0 3px 6px -4px rgba(0, 0, 0, 0.12),0 9px 28px 8px rgba(0, 0, 0, 0.05)'
  },
  ...customTheme
};

export type Theme = typeof theme;

export default extendThemeForStyledSystem(theme);
