import { CommentOutlined } from '@ant-design/icons';
import React, { ReactNode } from 'react';
import {
  VeltProvider as OriginalVeltProvider,
  VeltComments,
  VeltCommentBubbleWireframe,
  VeltCommentToolWireframe,
  VeltWireframe
} from '@veltdev/react';
import { createGlobalStyle } from 'styled-components';
import {
  CommentsSidebarStyles,
  CommentsSidebarWireframes
} from '../components/Velt/CommentsSidebarWireframe';
import {
  NotificationsWireframe,
  NotificationsStyles,
  NotificationsItemWireframe
} from '../components/Velt/NotificationsWireframe';
import {
  DeleteThreadStyles,
  DeleteThreadWireframe
} from '../components/Velt/DeleteThreadWireframe';

const VeltStyles = createGlobalStyle`
  .v-headline-message {
    line-height: 20px !important;
  }
  
  .velt-comment-pin--popover {
    cursor: pointer;
  }

  .cdk-overlay-container {
    left: 1px !important;
  }

  app-comment-dialog-composer-assign-user {
    display: none;
  }
  
  .snippyly-highlight.snippyly-highlight {
    outline: none !important;
  }

  .velt-comment-pin--popover {
    cursor: pointer !important;
    border-color: transparent var(--velt-light-mode-green) transparent transparent !important;
  }

  * {
    --velt-border-radius-2xs: 4px;
    --velt-border-radius-xs: 4px;
    --velt-border-radius-sm: 4px;
    --velt-border-radius-md: 4px;
    --velt-border-radius-lg: 16px;
    --velt-border-radius-xl: 4px;
    --velt-border-radius-2xl: 4px;
    --velt-border-radius-3xl: 4px;
    --velt-border-radius-full: 100%;

    --velt-light-mode-green: ${({ theme }) => theme.colors.primary.default};
    --velt-light-mode-accent-hover: ${({ theme }) => theme.colors.primary.hover};

    --velt-default-font-size: 14px;
    --velt-default-font-weight: 400;
    --velt-default-font-family: Avenir;
    --velt-primary-btn-color: ${({ theme }) => theme.colors.primary.default};
    --velt-comment-pin-highlight: ${({ theme }) =>
      theme.colors.primary.default};
    --velt-notifications-panel-selected-tab-bg-color: ${({ theme }) =>
      theme.colors.white};
    --velt-notifications-panel-comment-headline-color: ${({ theme }) =>
      theme.colors.text.default};
    --velt-notifications-panel-comment-message-color: ${({ theme }) =>
      theme.colors.text.secondary};
    --velt-notifications-panel-read-all-btn-color: ${({ theme }) =>
      theme.colors.link};
    --velt-comment-dialog-header-bg-color: ${({ theme }) =>
      theme.velt.openStatus};
  }`;

type Props = {
  children: ReactNode;
};

export const VeltProvider = ({ children }: Props) => (
  <OriginalVeltProvider apiKey={VELT_API_KEY}>
    <VeltStyles />
    <NotificationsStyles />
    <CommentsSidebarStyles />
    <DeleteThreadStyles />
    <VeltWireframe>
      <NotificationsWireframe />
      <NotificationsItemWireframe />
      <VeltCommentBubbleWireframe>
        <VeltCommentBubbleWireframe.CommentsCount />
      </VeltCommentBubbleWireframe>
      <VeltCommentToolWireframe>
        <CommentOutlined className="comment-icon" />
      </VeltCommentToolWireframe>

      <CommentsSidebarWireframes />
      <DeleteThreadWireframe />
    </VeltWireframe>

    <VeltComments
      popoverMode
      popoverTriangleComponent
      pinShadowDom={false}
      textMode={false}
      attachments={false}
      recordings="none"
      shadowDom={false}
      dialogShadowDom={false}
      privateCommentMode={false}
    />

    {children}
  </OriginalVeltProvider>
);
