import React, { useMemo, useState } from 'react';
import { Popover } from 'antd';
import styled from 'styled-components';
import { RateType } from '../../types';
import { CloseIcon } from '../../../../../theme/icons';

const PropertiesCountLabel = styled.u`
  cursor: pointer;
`;

const Title = styled.div`
  color: ${props => props.theme.colors.text.secondary};
  padding-bottom: 10px;
`;

const PropertyName = styled.div`
  padding-bottom: 4px;
`;

const PopoverContentWrapper = styled.div`
  width: 320px;
`;

const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  top: 16px;
  right: 16px;
`;

type Props = {
  properties: RateType['properties'];
};

export const PropertiesListPopover = ({ properties }: Props) => {
  const [open, setOpen] = useState(false);

  const content = useMemo(
    () => (
      <PopoverContentWrapper>
        <StyledCloseIcon onClick={() => setOpen(false)} />
        <Title>Properties</Title>
        {properties?.map(property => (
          <PropertyName key={property.id}>{property.name}</PropertyName>
        ))}
      </PopoverContentWrapper>
    ),
    [properties]
  );

  if (!properties?.length) {
    return null;
  }

  return (
    <Popover
      open={open}
      content={content}
      arrow={false}
      placement="bottomLeft"
      trigger="click"
      onOpenChange={setOpen}
    >
      <PropertiesCountLabel aria-label="Show properties">
        {properties?.length}
      </PropertiesCountLabel>
    </Popover>
  );
};
