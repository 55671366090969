import { useLDClient } from 'launchdarkly-react-client-sdk';

export enum FeatureFlags {
  Restrictions = 'enabling-restrictions-product',
  SplitTests = 'split-tests',
  ResetManualPrices = 'reset-manual-prices',
  NewQuickViewHideOptimalPrice = 'new-quick-view-hide-optimal-price',
  NewQuickViewHideOptimalPriceCurve = 'new-quick-view-hide-optimal-price-curve',
  NewQuickViewOccupancyForecast = 'new-quick-view-occupancy-forecast',
  NewRestrictionsFramework = 'new-restrictions-framework',
  QuickViewRestrictionsTab = 'quick-view-restrictions-tab',
  GroupProfitability = 'group-profitability',
  NewGroupsKPIHeader = 'new-groups-kpi-header',
  UseExtraNetRevenueForecast = 'groups-use-extra-net-revenue',
  GroupsCollaborationTool = 'groups-collaboration',
  PriceSettingsMultipropertySelection = 'multiproperty-selection-for-price-settings',
  ShowLegacyInsights = 'show-insights-1-0',
  PricingMultipleRatePlans = 'pricing-multiple-rateplans'
}

export enum Entitlements {
  PaceAnalytics = 'entitle-PaceAnalytics',
  AnalyticsExplore = 'entitle-AnalyticsExplore'
}

type VariationsType = FeatureFlags | Entitlements;

const useFlagClient = () => {
  const ldClient = useLDClient();

  return {
    variation: (name: VariationsType, defaultValue: unknown) => {
      const value = ldClient?.variation(name, defaultValue);

      return value;
    },
    allFlags: () => {
      const flags = ldClient?.allFlags();
      return flags;
    }
  };
};

export const useFlag = (name: VariationsType) => {
  const { variation } = useFlagClient();

  return variation(name, false);
};
