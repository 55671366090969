import styled from 'styled-components';
import React from 'react';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

const StyledInfoCircleOutlined = styled(InfoCircleOutlined)`
  font-size: 14px;
  color: ${props => props.theme.colors.icon.default};
`;

const LabelWrapper = styled.div`
  margin-right: 4px;
`;

interface Props {
  label: string;
  tooltip: string;
}

export const FormItemLabelWithIcon = ({ label, tooltip }: Props) => (
  <>
    <LabelWrapper>{label}</LabelWrapper>
    <Tooltip title={tooltip}>
      <StyledInfoCircleOutlined />
    </Tooltip>
  </>
);
