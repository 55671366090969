import React from 'react';
import styled, { css } from 'styled-components';
import { DownOutlinedIcon } from '../Down';

const ExpandIcon = styled.a`
  color: ${props => props.theme.colors.primary.default};
  margin-right: 0.5em;
  position: relative;
  top: 2px;
`;

const StyledDownOutlinedIcon = styled(DownOutlinedIcon)<{
  $expanded: boolean;
  $noStyle?: boolean;
}>`
  transform: rotate(${props => (props.$expanded ? 0 : -90)}deg);
  transition: transform 0.2s ease;

  ${props =>
    props.$noStyle
      ? ''
      : css`
          cursor: pointer;

          path {
            stroke: ${props.theme.colors.icon.secondary};
          }

          :hover {
            path {
              stroke: ${props.theme.colors.primary.default};
            }
          }
        `}
`;

interface Props<T> {
  record: T;
  expandable: boolean;
  expanded: boolean;
  noStyle?: boolean;
  wrapperStyle?: React.CSSProperties;
  className?: string;
  onExpand?: (
    record: T,
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => void;
}

export const CustomExpandIcon = <T,>(props: Props<T>) =>
  props.expandable ? (
    <ExpandIcon
      className={props.className}
      onClick={e => {
        props.onExpand?.(props.record, e);
      }}
      style={props.wrapperStyle}
      data-testid={props.expanded ? 'collapse-group' : 'expand-group'}
    >
      <StyledDownOutlinedIcon
        $noStyle={props.noStyle}
        $expanded={props.expanded}
      />
    </ExpandIcon>
  ) : null;
