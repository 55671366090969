import React from 'react';
import { Button, Tooltip, Flex } from 'antd';
import { useTheme } from 'styled-components';

import { ConfirmableButton } from './ConfirmableButton';
import {
  ActionButtonsContainer,
  Container,
  Footer,
  Spacer,
  StyledExclamationCircleOutlined,
  StyledText,
  Wrapper
} from './styles';
import { HorizontalRule } from '../../theme/components';

interface Props {
  isDisabled?: boolean;
  isActionLoading?: boolean;
  isIndented?: boolean;
  isActionDisabled?: boolean;
  confirmUndo?: boolean;
  createGroupTooltip?: React.ReactNode;
  undo?: string;
  action: string;
  createGroup?: React.ReactNode;
  errorMessage?: string;
  linkButtons?: {
    label: string;
    confirm: boolean;
    action: () => void;
  }[];
  className?: string;
  onCreateGroup?: () => void;
  onUndo?: () => void;
  onClick: () => void;
}

export const ActionFooter = ({
  action,
  undo,
  isDisabled,
  onClick,
  onUndo,
  createGroup,
  createGroupTooltip,
  onCreateGroup,
  confirmUndo = true,
  linkButtons,
  isActionDisabled,
  isActionLoading,
  isIndented,
  errorMessage,
  className
}: Props) => {
  const theme = useTheme();

  return (
    <Footer className={className}>
      <Wrapper isIndented={isIndented}>
        <Spacer isIndented={isIndented}>
          <HorizontalRule />
          <Container className="buttons-container" errorMessage={errorMessage}>
            {createGroup && (
              <Tooltip title={createGroupTooltip}>
                <Button
                  type="primary"
                  onClick={onCreateGroup}
                  style={{ position: 'absolute', left: '20px' }}
                >
                  {createGroup}
                </Button>
              </Tooltip>
            )}

            {errorMessage && (
              <Flex justify="center" align="center">
                <StyledExclamationCircleOutlined
                  color={theme.colors.error.default}
                />
                <StyledText>{errorMessage}</StyledText>
              </Flex>
            )}

            {linkButtons?.map(({ label, action, confirm }) => (
              <ConfirmableButton
                key={label}
                buttonProps={{
                  type: 'link',
                  style: { margin: '0px 0px 0px -10px' }
                }}
                {...{ label, action, confirm }}
              />
            ))}
            <ActionButtonsContainer>
              {undo && (
                <ConfirmableButton
                  confirm={confirmUndo}
                  label={undo}
                  action={onUndo}
                  buttonProps={{
                    type: 'primary',
                    disabled: isDisabled,
                    ghost: true,
                    style: { margin: '0 5px' }
                  }}
                />
              )}
              <Button
                type="primary"
                disabled={isDisabled || isActionDisabled}
                onClick={onClick}
                loading={isActionLoading}
                style={{ margin: '0 5px' }}
              >
                {action}
              </Button>
            </ActionButtonsContainer>
          </Container>
        </Spacer>
      </Wrapper>
    </Footer>
  );
};
