import React, { ComponentProps, useCallback, useEffect, useMemo } from 'react';
import cn from 'classnames';
import styled, { css } from 'styled-components';
import { useAtom } from 'jotai';
import { useLocation } from 'react-router-dom';
import { Button, ConfigProvider, Menu } from 'antd';
import fullLogo from '../../../../theme/logos/flyr-logo-green.svg';
import logoIcon from '../../../../theme/logos/flyr-logo-green-icon.svg';
import { getCleanUrlRootPath } from '../../../../utils/urls';
import { showNotificationsAtom } from '../../../../store/showNotificationsAtom';
import { DownOutlinedIcon } from '../../../../theme/icons/Down';

export type MenuItems = NonNullable<ComponentProps<typeof Menu>['items']>;

const CollapseIcon = styled(DownOutlinedIcon)<{ $collapsed: boolean }>`
  transition: transform 0.2s ease;

  svg {
    width: 24px;
    height: 24px;
  }

  path {
    stroke: ${props => props.theme.colors.icon.secondary};
  }

  transform: rotate(${props => (props.$collapsed ? -90 : 90)}deg);
`;

const CollapseButton = styled(Button)<{ $collapsed: boolean }>`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    position: absolute;

    transition: all 0.3s;

    ${props => {
      if (!props.$collapsed) {
        return css`
          right: 0;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          width: 24px;
          background: rgba(0, 0, 0, 0.04);

          :hover {
            background: rgba(0, 0, 0, 0.1);
          }
        `;
      }

      return css`
        background: #e9ecea !important;
        right: 24px;
      `;
    }}
  }
`;

const StyledWrapper = styled.div`
  z-index: 100;
  display: flex;
  flex-direction: column;
  height: inherit;
  background-color: #fafafa;

  transition: width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;

  :hover {
    ${CollapseButton} {
      opacity: 1;
    }
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 75px;
`;

const MenuScrollWrapper = styled.div`
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
`;

const StyledMenu = styled(Menu)<{ $bottomItemsCount: number }>`
  min-height: 500px;
  height: 100%;
  background-color: #fafafa;
  position: relative;

  && .bottom-item {
    position: absolute !important;

    ${props => {
      const baseBottom = 10;
      const bottomIncrement = 45;

      // Position bottom items absolutely to the bottom with even spacing
      let css = '';
      for (let i = 0; i < props.$bottomItemsCount; i++) {
        css += `&-${i} { bottom: ${baseBottom + i * bottomIncrement}px; }`;
      }

      return css;
    }}

    &.has-notifications {
      &::after {
        content: '';
        position: absolute;
        top: ${props => (props.inlineCollapsed ? '2px' : '6px')};
        right: ${props => (props.inlineCollapsed ? '10px' : '21px')};
        border-radius: 10px;
        background-color: ${props => props.theme.colors.badge.alert};
        padding: 5px;
        border: 1px solid white;
      }
    }
  }

  && .Canny_BadgeContainer .Canny_Badge {
    top: ${props => (props.inlineCollapsed ? '2px' : '6px')};
    right: ${props => (props.inlineCollapsed ? '10px' : '46px')};
    background-color: ${props => props.theme.colors.badge.alert};
  }
`;

const FlyrFullLogo = styled.img.attrs({
  src: fullLogo,
  alt: 'FLYR for Hospitality'
})`
  padding-left: 24px !important;
  width: 190px;
`;

const FlyrLogoIcon = styled.img.attrs({
  src: logoIcon,
  alt: 'FLYR for Hospitality'
})`
  width: 50px;
  height: 34px;
`;

interface Props {
  topItems: MenuItems;
  bottomItems: MenuItems;
  collapsed: boolean;
  onSetCollapsed: (collapsed: boolean) => void;
}

export const SideNavigation = ({
  topItems,
  bottomItems,
  collapsed,
  onSetCollapsed
}: Props) => {
  const [showNotifications, setShowNotifications] = useAtom(
    showNotificationsAtom
  );
  const closeNotifications = useCallback(() => {
    if (showNotifications) {
      setShowNotifications(false);
    }
  }, [setShowNotifications, showNotifications]);
  const width = useMemo(() => (collapsed ? 80 : 180), [collapsed]);
  const { pathname } = useLocation();

  const selectedRoute = useMemo(() => {
    const activeRoute = [...topItems, ...bottomItems].find(item => {
      // we cannot compare with dynamic search params, remove everything after ?
      const cleanItemPath = getCleanUrlRootPath(item?.key as string);

      return (
        cleanItemPath === pathname || pathname.includes(`${cleanItemPath}/`)
      );
    });

    return (activeRoute?.key as string) || '/';
  }, [pathname, topItems, bottomItems]);

  useEffect(() => {
    if (['/insights', '/insights-v2'].includes(selectedRoute)) {
      onSetCollapsed(true);
    }
  }, [selectedRoute, onSetCollapsed]);

  return (
    <ConfigProvider
      theme={{
        components: {
          Menu: {
            fontSize: 14,
            iconSize: 16,
            activeBarBorderWidth: 0,
            itemColor: '#38414c',
            itemSelectedColor: '#00a484',
            itemMarginInline: 12
          }
        }
      }}
    >
      <StyledWrapper style={{ width }} onClick={closeNotifications}>
        <LogoWrapper>
          {collapsed ? <FlyrLogoIcon /> : <FlyrFullLogo />}
          <CollapseButton
            type="text"
            icon={<CollapseIcon $collapsed={collapsed} />}
            $collapsed={collapsed}
            onClick={() => onSetCollapsed(!collapsed)}
          />
        </LogoWrapper>
        <MenuScrollWrapper>
          <StyledMenu
            style={{ width }}
            inlineCollapsed={collapsed}
            $bottomItemsCount={bottomItems.length}
            selectedKeys={[selectedRoute]}
            mode="vertical"
            items={[
              ...topItems,
              ...(bottomItems.map((i, index) => ({
                ...i,
                className: cn(`bottom-item bottom-item-${index}`, i?.className)
              })) as MenuItems)
            ]}
          />
        </MenuScrollWrapper>
      </StyledWrapper>
    </ConfigProvider>
  );
};
